import {forwardRef} from 'react';
import {Container, type ContainerProps, Flex, Text} from '@chakra-ui/layout';

import {useTranslate} from '@eksab/i18n';
import {Image} from '@eksab/components/Image';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';

import {GameCard} from './GameCard';
import gamepad from './gamepad.webp';

// eslint-disable-next-line react/display-name
export const OurGames = forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
  const t = useTranslate();

  const isTacticEnabled = !!useRegionSettings().data?.settings.tactic_enabled;

  return (
    <Container
      as="section"
      ref={ref}
      flexDir="column"
      gap={[4, 6, 8, 10]}
      px={[5, 8, 12, 16]}
      py={[4, 8, 12, 16]}
      w={['calc(100% - 1rem * 2)', 'calc(100% - 1.5rem * 2)', 'calc(100% - 2rem * 2)']}
      bg="green.50"
      rounded="2rem"
      {...props}
    >
      <Flex as="header" flexDir="column" align="center" gap={[1, 2]} mb={[2, 4, 5, 6]}>
        <Image src={gamepad} alt="" width={120} w={[12, 16, 28, '7.5rem']} />

        <Text
          as="h2"
          fontSize={['xl', '2xl', '2rem']}
          fontWeight="800"
          bg="linear-gradient(360deg, #408C57, #5AC57B)"
          bgClip="text"
          color="transparent"
        >
          {t('home.games.title')}
        </Text>
      </Flex>

      <GameCard game="trivia-sports" />

      <GameCard game="trivia-art" />

      <GameCard game="fantasy" />

      {isTacticEnabled && <GameCard game="tactic" />}
    </Container>
  );
});
