import {useRouter} from 'next/router';
import {ContainerProps, Container, Flex, Link} from '@chakra-ui/layout';
import {Image} from '@chakra-ui/image';

import {getLanguage} from '@eksab/util';

import {featuredInList} from './data';

export const FeaturedIn = (props: ContainerProps) => {
  const {locale} = useRouter();

  const featuredIn = featuredInList[getLanguage(locale)];

  return (
    <Container as="section" flexDir="column" alignItems="center" gap={[2, 4, 6, 12]} py={[2, 3, 4]} {...props}>
      <Container
        variant="scroll"
        px="0"
        maxW="full"
        h="16"
        alignItems="center"
        justifyContent="space-between"
        gap={[10, 16, 24, 32]}
      >
        {featuredIn.map(({name, title, logo, article}) => (
          <Link
            key={name}
            title={title}
            href={article}
            target="_blank"
            py="2"
            h="full"
            opacity={{lg: 0.15}}
            transition="opacity 0.3s ease-out"
            _hover={{opacity: 1}}
          >
            <Image
              src={logo}
              alt={name}
              minW={[28, 32, 36]}
              h="full"
              objectFit="contain"
              decoding="async"
              loading="lazy"
            />
          </Link>
        ))}
      </Container>

      <Flex w={[32, 48, 60, 72]} h="1px" bgGradient="linear(to-r, transparent 0%, #6C6C6C 50%, transparent 100%)" />
    </Container>
  );
};
