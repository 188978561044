import {Container, type ContainerProps, Flex, Text} from '@chakra-ui/layout';
import {Button} from '@chakra-ui/button';
import {ArrowDownIcon} from '@chakra-ui/icons';

import {Image} from '@eksab/components/Image';
import {useTranslate} from '@eksab/i18n';

import heroBg from './hero-bg.webp';
import crownIcon from './crown.png';
import heroImage from './hero-image.webp';

interface HeroProps extends ContainerProps {
  onCTA: () => void;
}

export function Hero({onCTA, ...props}: HeroProps) {
  const t = useTranslate();

  return (
    <Container
      as="section"
      gap={[4, 6, 8]}
      pos="relative"
      minH={{lg: '70vh'}}
      w={['calc(100% - 1rem * 2)', 'calc(100% - 1.5rem * 2)', 'calc(100% - 2rem * 2)']}
      flexDir={['column-reverse', null, 'row']}
      alignItems="center"
      justifyContent={['flex-start', null, 'space-between']}
      textAlign={['center', null, 'start']}
      rounded={['1rem', null, '2rem']}
      isolation="isolate"
      overflow="hidden"
      style={{paddingBlock: 'clamp(1.5rem, 5vw, 3.75rem)'}}
      {...props}
    >
      <Image
        src={heroBg}
        width={1440}
        height={640}
        alt=""
        priority
        pos="absolute"
        inset={0}
        borderRadius="inherit"
        zIndex={-1}
        objectFit="cover"
        h="full"
      />

      <Flex flexDir="column" align={['center', null, 'flex-start']} gap={[2, null, 4]} maxW="32rem">
        <Flex px={[2, null, 4]} py={[2, null, 3]} gap="2" align="center" bg="#f2f786" rounded="0.875rem">
          <Image src={crownIcon} alt="" width={24} w={[4, 5, 6]} />

          <Text fontSize="xs" fontWeight="600" color="#5A5C00">
            {t('home.hero.banner', {
              b: (chunks) => (
                <Text as="strong" color="inherit" fontWeight="700">
                  {chunks}
                </Text>
              ),
            })}
          </Text>
        </Flex>

        <Text as="h1" fontSize={['md', 'xl', '2xl', '4xl']} fontWeight="800" color="gray.900">
          {t('home.hero.heading')}
        </Text>

        <Text fontSize={['xs', 'sm', 'md', 'xl']} fontWeight="500" color="gray.900">
          {t('home.hero.description', {
            strong: (chunks) => (
              <Text as="strong" display="block" mt="3" color="green.700" fontWeight="800">
                {chunks}
              </Text>
            ),
          })}
        </Text>

        <Button
          mt={[2, null, 4]}
          fontWeight="700"
          fontSize={['xs', 'sm', 'md']}
          h="unset"
          lineHeight="1.5"
          ps={[3.5, 5, 6]}
          pe={[2, 3, 4]}
          py={[2.5, 3, 4]}
          gap="3"
          onClick={onCTA}
        >
          {t('home.hero.cta')}

          <ArrowDownIcon w={[3, null, 4]} h="auto" />
        </Button>
      </Flex>

      <Image src={heroImage} alt="" width={470} w={['11rem', '16rem', '24rem', '30rem']} />
    </Container>
  );
}
